<template>
  <a-select
    allow-clear
    show-search
    :value="keyword"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="fetching ? undefined : null"
    @focus="clearKeywords"
    @search="handleSearch"
    @change="handleChange"
  >
    <template>
      <a-spin v-if="fetching" slot="notFoundContent" size="small" />
      <a-select-option v-for="d in data" :key="d.location">
        {{ d.text }}
      </a-select-option>
    </template>
  </a-select>
</template>
<script>
import { mapUrl } from '@/common/const'
export default {
  name: 'MapSearch',
  parops: {},
  data() {
    return {
      // search
      keyword: null,
      data: [],
      currentValue: '',
      fetching: false,
      noticeTitle: '地图位置'
    }
  },
  methods: {
    // search
    handleSearch(value) {
      this.fetch(value, data => (this.data = data))
    },
    handleChange(value, node) {
      this.$emit('emitValue', value)
      this.keyword = value
      this.fetch(value, data => {
        this.data = data
      })
    },
    clearKeywords() {
      this.keyword = ''
      this.data = []
    },
    // @focus="findAll"
    // findAll(value) {
    //   this.fetch('', data => (this.data = data))
    // },
    fetch(value, callback) {
      this.currentValue = value
      this.fake(value, callback)
    },
    fake(value, callback) {
      this.fetching = true
      const { currentValue } = this
      this.getSearchResult(value, currentValue, callback)
    },
    getSearchResult(value, currentValue, callback) {
      const params = {
        children: '',
        page: 1,
        offset: 10,
        city: 500000,
        sdkversion: '1.3',
        keywords: value,
        appname: 'https%3A%2F%2Flbs.amap.com%2Fconsole%2Fshow%2Fpicker'
      }
      const url = mapUrl(params)
      fetch(url)
        .then(response => response.json())
        .then(body => {
          if (currentValue === value) {
            console.log(body)
            const result = body.pois
            const data = []
            console.log(body, 'result')
            if (body.pois) {
              result.forEach(r => {
                data.push({
                  id: r.id,
                  value: r.name,
                  text: r.name,
                  location: r.location + ',' + r.name
                })
              })
            }
            callback(data)
            this.fetching = false
          }
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
