<template>
  <a-alert class="table_msg" type="success">
    <template slot="message">
      <span style="margin-right: 12px">总计: <a style="font-weight: 600">{{ totalCount }}</a></span>
    </template>
  </a-alert>
</template>
<script>
export default {
  name: 'MyAlert',
  props: {
    totalCount: {
      type: Number,
      default: 0
    }
  }
}
</script>
