<template>
  <div>
    <a-tooltip v-if="card && !img" placement="right" :title="file.name">
      <a-card hoverable :style="{ width: width + 15 + 'px' }">
        <img
          class="myImg"
          slot="cover"
          alt="example"
          :src="file.url ? file.url : file.thumbUrl ? file.thumbUrl : host + file.path + THUMBNAIL_ORIGIN"
          @click="handlePreview"
        />
        <a-card-meta :title="file.name"> </a-card-meta>
      </a-card>
    </a-tooltip>
    <a-tooltip v-if="!card && !img" placement="right" :title="file.name ? file.name : file.id">
      <a-card :style="{ width: width + 15 + 'px' }">
        <img
          class="myImg"
          slot="cover"
          alt="example"
          :src="file.url ? file.url : file.thumbUrl ? file.thumbUrl : host + file.path + THUMBNAIL_ORIGIN"
          @click="handlePreview"
        />
      </a-card>
    </a-tooltip>
    <div v-if="img" class="container" @mouseenter="enterWraper('wraper')" @mouseleave="leaveWraper('wraper')">
      <div class="wraper" @mouseenter="enterWraper('img')" @mouseleave="leaveWraper('img')">
        <img
          class="myImg"
          slot="cover"
          alt="example"
          :src="file.url ? file.url : file.thumbUrl ? file.thumbUrl : host + file.path + THUMBNAIL_ORIGIN"
        />
        <div :style="{ opacity: opacity }" class="mask">
          <span class="handle" :style="{ opacity: handleOpacity }">
            <a @click="handlePreview">
              <a-icon type="eye" />
            </a>
          </span>
        </div>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel('img')">
      <img alt="example" style="width: 100%; height: 100%;" :src="previewImage" />
    </a-modal>

    <a-modal :visible="videoVisible" :footer="null" @cancel="handleCancel('video')" class="videoModal">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
    </a-modal>
  </div>
</template>

<script>
import { THUMBNAIL_ORIGIN } from '@/common/const'
export default {
  name: 'Media',
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 96
    },
    height: {
      type: Number,
      default: 96
    },
    card: {
      type: Boolean,
      default: false
    },
    img: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.THUMBNAIL_ORIGIN = THUMBNAIL_ORIGIN
    return {
      // 文件上传
      previewVisible: false,
      videoVisible: false,
      opacity: 0,
      handleOpacity: 0,
      previewImage: '',
      // 播放 vedio
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: '', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: '' // url地址
          }
        ],
        poster: '', // 你的封面地址
        // width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  computed: {
    host() {
      return this.$store.state.oss.host
    }
  },
  methods: {
    handleCancel(val) {
      switch (val) {
        case 'img':
          this.previewVisible = false
          break
        case 'video':
          this.videoVisible = false
          break
        default:
          this.previewVisible = false
          this.videoVisible = false
          break
      }
    },
    handlePreview() {
      const { file } = this
      let type = ''
      if (file.path) {
        type = file.path.slice(file.path.lastIndexOf('.') + 1)
      } else if (file.type) {
        type = file.type.slice(file.type.lastIndexOf('/') + 1)
      }
      if (type === 'mp4') {
        this.playerOptions.sources[0].src = file.src
        this.playerOptions.poster = file.url
        this.videoVisible = true
      } else {
        this.previewVisible = true
        if (file.url) {
          this.previewImage = file.url
        } else if (file.path) {
          this.previewImage = this.host + file.path + THUMBNAIL_ORIGIN
        }
      }
    },
    enterWraper(type) {
      switch (type) {
        case 'wraper':
          this.opacity = 1
          break
        case 'img':
          this.handleOpacity = 1
          break
      }
    },
    leaveWraper(type) {
      switch (type) {
        case 'wraper':
          this.opacity = 0
          break
        case 'img':
          this.handleOpacity = 0
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  position: relative;
  float: left;
  padding: 8px;
  margin: 0 8px 8px 0;
  height: 114px;
  width: 114px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  overflow: hidden;
  .wraper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: ' ';
    a {
      color: #fff;
      font-size: 18px;
    }
    .handle {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      color: #fff;
      white-space: nowrap;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
}
</style>
