<template>
  <div>
    <a-select
      allow-clear
      show-search
      :value="value"
      placeholder="请选择驻场人员"
      style="width: 200px"
      :default-active-first-option="false"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="handleSearch"
      @change="handleChange"
      @focus="findAll"
      v-decorator="['developer', { rules: [{ required: true, message: '请选择驻场人员' }] }]"
    >
      <a-select-option v-for="d in data" :key="d.link">
        {{ d.text }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { findResidents } from '@/api/residents'

let timeout
let currentValue

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  currentValue = value

  function fake() {
    const params = {
      keyword: currentValue,
      page: 0,
      size: 1000,
      sort: ''
    }
    findResidents(params).then(d => {
      if (currentValue === value) {
        const result = d._embedded.residents
        const data = []
        result.forEach(r => {
          data.push({
            id: r.id,
            value: r.name,
            text: r.name,
            link: r._links.self.href
          })
        })
        callback(data)
      }
    })
  }

  timeout = setTimeout(fake, 300)
}
export default {
  name: 'MyQuery',
  props: {
    keyword: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // search
      data: [],
      value: undefined
    }
  },
  methods: {
    // search
    handleSearch(value) {
      fetch(value, data => (this.data = data))
    },
    handleChange(value, node) {
      this.$emit('emitQuery', value)
      this.value = value
      fetch(value, data => (this.data = data))
    },
    findAll(value) {
      fetch('', data => (this.data = data))
    }
  }
}
</script>
