<template>
  <div>
    <a-upload
      list-type="picture-card"
      :file-list="media"
      :before-upload="beforeUpload"
      :multiple="true"
      @preview="handlePreview"
      @change="handleChange"
    >
      <div v-if="media.length < maxLength">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传
        </div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel('img')">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal :visible="videoVisible" :footer="null" @cancel="handleCancel('video')" class="videoModal">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
    </a-modal>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  name: 'UploadOss',
  props: {
    media: {
      type: Array,
      default: () => []
    },
    maxLength: {
      type: Number,
      default: 9
    }
  },
  data() {
    return {
      // 文件上传
      previewVisible: false,
      videoVisible: false,
      previewImage: '',
      // 播放 vedio
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: '', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: '' // url地址
          }
        ],
        poster: '', // 你的封面地址
        // width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  methods: {
    handleCancel(val) {
      switch (val) {
        case 'video':
          this.videoVisible = false
          break;
        default:
          this.previewVisible = false
          break;
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      let type = ''
      if (file.path) {
        type = file.path.slice(file.path.lastIndexOf('.') + 1)
      } else if (file.type) {
        type = file.type.slice(file.type.lastIndexOf('/') + 1)
      }
      console.log(type, 'pre type')
      if (type === 'mp4') {
        this.playerOptions.sources[0].src = file.src
        this.playerOptions.poster = file.url
        this.videoVisible = true
      } else {
        this.previewVisible = true
        if (file.url) {
          this.previewImage = file.url
        } else {
          this.previewImage = file.preview
        }
      }
    },
    handleChange({ fileList }) {
      this.$emit('emitFiles', fileList)
    },
    beforeUpload(file) {
      return false
    }
  }
}
</script>
